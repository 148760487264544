.wrapper {
  width: 280px;
  padding: 32px 34px;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.02);
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.03));
  background: #FEFEFE;
  border-radius: 4px;
  position: absolute;
  top: 54px;
  right: 0;
  z-index: 20;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.abbreviationWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.abbreviation {
  text-decoration: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #565E6E;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.userName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #313745;
  text-align: center;
  margin-bottom: 5px;
}

.email {
  font-size: 14px;
  line-height: 22px;
  color: #6C7689;
  text-align: center;
  margin-bottom: 42px;
}

.action {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 40px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  color: #313745;

  &:last-child {
    margin-bottom: 0;
  }
}