.navbar {
  padding: 16px 0;
  border-bottom: 1px solid #EBECEE;
  position: relative;
  z-index: 20;
  
  @media (max-width: 1200px) {
    //padding-left: 70px;
    //padding-right: 20px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.secondRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #313745;
  margin-right: 16px;
}

.zipCode {
  font-size: 14px;
  line-height: 22px;
  color: #6C7689;
}

.currentDate {
  font-size: 14px;
  line-height: 22px;
  color: #6C7689;
  margin-right: 64px;
}

.profileWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.abbreviation {
  text-decoration: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #565E6E;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.field {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #6C7689;
  display: flex;
  align-items: center;
  position: relative;

  &Normal {
    font-weight: normal;
  }

  &Arrow {
    margin-left: 6px;
    cursor: pointer;
  }
}

.list {
  position: relative;
  margin-right: 38px;
}

.countWrapper {
  width: 20px;
  height: 15px;
  background: #FFC008;
  border-radius: 7.5px;
  position: absolute;
  top: -8px;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 9px;
  color: #313745;
}

.listContainer {
  width: 320px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(167, 173, 184, 0.3);
  border-radius: 4px;
  padding: 20px 24px;
  position: absolute;
  right: 0;
  top: 15px;
  z-index: 20;
}

.listIcon {
  cursor: pointer;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  line-height: 17px;
  color: #565E6E;
  margin-bottom: 24px;
  text-decoration: none;
}

.listMarker {
  width: 8px;
  height: 8px;
  border-radius: 1px;
}

.filterIcon {
  margin-right: 33px;
  cursor: pointer;
}

.models {
  background-color: #F5F5F5;
  list-style: none;
  position: absolute;
  width: 220px;
  box-sizing: border-box;
  top: 20px;
  right: 0;
  padding: 10px;
  z-index: 5;

  &Item {
    margin-bottom: 15px;
    font-weight: normal;
    cursor: pointer;
    text-align: right;
  }
}