.backdrop {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
}

.modal {
  min-width: 550px;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 21px 16px;
  background: #FFFFFF;
  box-shadow: -1px -4px 20px rgba(163, 174, 208, 0.08), 0px 16px 40px rgba(163, 174, 208, 0.12);
  border-radius: 15px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 430px) {
    min-width: 90%;
    max-width: 90%;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  font-size: 14px;
  color: #414752;
}

.closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}