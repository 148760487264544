.wrapper {
  //margin-bottom: 16px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #313745;
  margin-bottom: 9px;
  display: block;
  width: 100%;
}

.input {
  width: 400px;
  height: 41px;
  box-sizing: border-box;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 17px;
  color: #313745;
  border: 1px solid #EBECEE;
  border-radius: 4px;

  @media (max-width: 780px) {
    width: 100%;
    max-width: 300px;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 17px;
    color: #A7ADB8;
  }
}

.icon {
  position: relative;
  left: -32px;
  top: 3px;

  @media (max-width: 780px) {
    display: none;
  }
}