.wrapper {
  margin-top: 25px;
}

.text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 22px;
  color: #6C7689;
  text-align: center;
}

.blockTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #414752;
}

.twoFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.threeFields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.checkboxWrapper {
  margin-bottom: 15px;
}

.checkbox {
  border: 1px solid #C4C8D0;
  border-radius: 3px;
}

.block {
  width: 565px;
  padding: 32px;
  display: grid;
  grid-template-columns: 60% 40%;
  background: #F9F9FB;
  margin-bottom: 16px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 32px;
}

.price {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}

.priceValue {
  font-weight: bold;
}

.promoText {
  font-size: 14px;
  line-height: 22px;
  color: #565E6E;
}

.grassType {
  margin-bottom: 12px;
}

.btnWrapper {
  text-align: center;
}

.firstBtn {
  margin-right: 8px;
}
