.wrapper {
  display: grid;
  grid-template-columns: 427px 1fr;
  min-height: 100vh;

  @media (max-width: 1200px) {
    grid-template-columns: 350px 1fr;
  }

  //@media (max-width: 780px) {
  //  grid-template-columns: 300px 1fr;
  //}

  @media (max-width: 780px) {
    grid-template-columns: 1fr;
  }
}

.animation {
  background: url("./sign-img.png") no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.logo {
  position: absolute;
  top: 106px;
  left: 50%;
  transform: translateX(-50%);
}

.container {
  max-width: 565px;
  margin: 0 auto;
  padding-top: 130px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 780px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &:has([data-type="models-list"]) {
    max-width: 80%;
    padding-top: 50px;

    form{
      padding-bottom: 20px !important;
    }

    @media (max-width: 1320px) {
      max-width: 95%;
    }
  }
}

.fullWidthContainer {
  max-width: 90%;
}
