.btn {
  padding: 8px 24px;
  border-radius: 3px;
  background-color: #EBECEE;
  font-size: 14px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  color: #000;

  @media (max-width: 440px) {
    color: #000;
  }

  &:disabled {
    background: #F7F7FA;
    color: #6C7689;
    cursor: not-allowed;
  }

  &.active {
    background: #7AA579;
    color: #fff;
  }
}