.wrapper {
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  background-position: center;
  will-change: opacity;
  position: relative;

  @media (max-width: 780px) {
    min-height: 50vh;
    max-height: 50vh;
  }
}

.logo {
  position: absolute;
  top: 106px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 780px) {
    top: 50px;
  }
}