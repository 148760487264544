.btn {
  cursor: pointer;
  width: 36px;
  position: relative;
  left: 15px;
  height: 25px;
  margin-top: 22px;
  margin-bottom: 48px;

  & span {
    width: 30px;
    height: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #565E6E;
    transition: all 0.5s;
  }

  &:global(.active) span:nth-of-type(2) {
    top: 50%;
    transform: translate(-50%, 0%) rotate(45deg);
  }

  &:global(.active) span:nth-of-type(1) {
    display: none;
  }

  &:global(.active) span:nth-of-type(3) {
    top: 50%;
    transform: translate(-50%, 0%) rotate(-45deg);
  }

  & span:nth-of-type(2) {
    top: calc(50% - 10px);
  }

  & span:nth-of-type(3) {
    top: calc(50% + 10px);
  }

  //& .line {
  //  display: block;
  //  height: 5px;
  //  background-color: #565E6E;
  //  margin-bottom: 5px;
  //}
  //
  //&:global(.active) .line {
  //  &:nth-of-type(1) {
  //    display: none;
  //  }
  //
  //  &:nth-of-type(2) {
  //    transform: rotate(45deg);
  //  }
  //
  //  &:nth-of-type(3) {
  //    //transform: rotate(-45deg);
  //    display: none;
  //  }
  //}
}

