.wrapper {
  margin-top: 45px;
  text-align: center;
}

.text {
  font-size: 16px;
  line-height: 24px;
  color: #414752;
  margin-bottom: 15px;
}

.btnWrapper {
  margin-top: 40px;
}

.firstBtn {
  margin-right: 8px;
}