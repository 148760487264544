.sidebar {
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;

  @media(max-width: 1200px) {
    min-height: auto;
    height: auto;
  }

  &.collapse {
    width: 60px;
  }

  &.open {
    width: 240px;
  }
}

.logo {
  display: inline-block;
  margin-top: 17px;
  margin-bottom: 48px;
  position: relative;
  left: 15px;
}

.userMenu {
  height: calc(100vh - 97px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @media(max-width: 1200px) {

  }

  &Item {
    height: 38px;
    width: calc(100% - 11px);
    border-radius: 4px 0px 0px 4px;
    padding: 11px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    color: #313745;
    margin-bottom: 20px;

    &:nth-child(1) {
      margin-bottom: auto;
    }

    &:global(.active) {
      color: #fff;
      background: #565E6E;

      & svg path {
        fill: #fff;
      }
    }
  }

  &Icon {
    margin-right: 13px;
  }
}


.adminMenu {
  height: calc(100vh - 97px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &Item {
    height: 38px;
    width: calc(100% - 11px);
    border-radius: 4px 0px 0px 4px;
    padding: 11px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    color: #313745;
    margin-bottom: 20px;

    &:global(.active) {
      color: #fff;
      background: #565E6E;

      & svg path {
        fill: #fff;
      }
    }
  }

  &Icon {
    margin-right: 13px;
  }
}
