
.closeIcon {
  position: absolute;
  top: 37px;
  right: 37px;
  cursor: pointer;
}

.settingWrapper {
  position: fixed;
  top: 166px;
  right: calc((100% - 1020px) / 2);
  position: absolute;
  top: -20px;
  right: 0;
  width: 320px;
  background-color: #fff;
  z-index: 10;
  padding-top: 32px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  box-sizing: border-box;
  //max-height: 90vh;
  //overflow-y: scroll;
}

.settingTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #313745;
  margin-bottom: 24px;
}

.settingLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #313745;
  margin-bottom: 18px;
}

.settingBlock {
  margin-bottom: 24px;
}

.settingItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #313745;
  margin-bottom: 5px;

  & input {
    margin-right: 10px;
  }
}
